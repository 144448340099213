<template>
  <div id="globalApp">
    <router-view />
  </div>
</template>

<script setup>
import global from '@/global/js/global';
global.getBase();
</script>

<style lang="scss">
/* 全局颜色 */
@import '@/global/scss/global-color.scss';
@import '@/global/scss/element.scss';
/* 公共样式 */
@import '@/assets/css/comm.css';
@import '@/assets/css/layout.css';
@import '@/assets/css/scrollbar.css';

/* 图标 */
@import '@/assets/iconfont/iconfont.css';

#globalApp {
  min-width: 1200px;
  overflow: hidden;
  height: 100vh;
  background-image: url('@/assets/images/bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFFFFF;
  color: #050e17;
  font-weight: bold;
}

* {
  font-family: PingFangSCRegular !important;
}

/* 自定义字体 */
@font-face {
  font-family: PingFangSCRegular;
  src: url('@/assets/customfont/PingFangSCRegular.ttf') format('truetype');
}

.pingFangSC {
  font-family: PingFangSCRegular !important;
}

@font-face {
  font-family: alimamashuheiti;
  src: url('@/assets/customfont/alimamashuheiti.ttf') format('truetype');
}

.alimamas {
  font-family: alimamashuheiti !important;
}


.overlayImg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
