import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      label: ''
    },
    component: function () {
      return import('../views/layout/index.vue')
    },
    redirect: '/home',
    children: [
      // 首页
      {
        path: '/home',
        name: '/home',
        meta: {
          title: '',
          label: ''
        },
        component: function () {
          return import('../views/home.vue')
        },
      },

      // 科目
      {
        path: '/subjects',
        name: '/subjects',
        meta: {
          title: '',
          label: ''
        },
        component: function () {
          return import('../views/subviews/subjects/subjects.vue')
        },
        redirect: '/subjectsTeacherIntroduction',
        children: [
          // 科目-讲师简介
          {
            path: '/subjects/subjectsTeacherIntroduction',
            name: '/subjects/subjectsTeacherIntroduction',
            meta: {
              title: '讲师简介',
              label: ''
            },
            component: function () {
              return import('../views/subviews/subjects/subjectsTeacherIntroduction.vue')
            },
          },

          // 科目-单元/目录
          {
            path: '/subjects/subjectsCatalogue',
            name: '/subjects/subjectsCatalogue',
            meta: {
              title: '单元',
              label: ''
            },
            component: function () {
              return import('../views/subviews/subjects/subjectsCatalogue.vue')
            },
          },

          // 科目-小节
          {
            path: '/subjects/subjectsLesson',
            name: '/subjects/subjectsLesson',
            meta: {
              title: '小节',
              label: ''
            },
            component: function () {
              return import('../views/subviews/subjects/subjectsLesson.vue')
            },
          },

          // 科目-小节
          {
            path: '/subjects/subjectsCourse',
            name: '/subjects/subjectsCourse',
            meta: {
              title: '课程',
              label: ''
            },
            component: function () {
              return import('../views/subviews/subjects/subjectsCourse.vue')
            },
          }
        ]
      },

      // 观看记录
      {
        path: '/history',
        name: '/history',
        meta: {
          title: '观看记录',
          label: ''
        },
        component: function () {
          return import('../views/subviews/history/history.vue')
        },
      },
    ]
  },
  {
    path: '/login',
    name: '/login',
    meta: {
      title: '登录',
      label: ''
    },
    component: function () {
      return import('../views/login/login.vue')
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 权限判断
import config from '@/api/config.js';
import global from '@/global/js/global.js';
import { ElMessage } from 'element-plus'
import store from '@/store'

// 全局导航守卫
router.beforeEach(async (to, from, next) => {
  // 网站路径名称
  if (to.meta.label) {
    document.title = to.meta.label + ` - ${config.brand_name}`
  } else {
    document.title = config.brand_name // 默认的title
  }

  // 访问路径不属于当前系统，则打开首页
  if (to.matched.length == 0) return next('/');

  // 判断是否存在token
  if (localStorage.getItem('token')) {
    if (!store.state.userInfo instanceof Object || Object.keys(store.state.userInfo).length == 0) {
      try {
        await global.getUserInfo();
      } catch (error) {
        error.data.code != 405 && ElMessage.error('身份信息已过期，请重新登录');

        return next('/login')
      }

      return next()
    }

    return next()

  } else if (to.path !== '/login') {

    return next({ path: '/login' })
  } else {

    return next()
  }

  return next()
});

export default router
