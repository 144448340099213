/**
 * @全局公共事件
 */

import request from "@/api/request.js";
import storage from "../js/comm/storage";
import router from "@/router/index.js";
import store from '@/store/index';
import { ElLoading } from 'element-plus';
import routerVerify from '../js/comm/routerVerify';
import CryptoJS from 'crypto-js';

export default {
    data() {
        return {
            elLoading: null, // 全局加载
            elRefLoading: null, // 元素加载
            color: 'var(--theme_color)', // 全局颜色
            maxNode: '#globalApp', // 最大节点
            globalFloat: 40, // 全局浮动固定高度
            throttleTimer: null, // 节流
            debounceTimer: null, // 防抖

            videoCryptoData: {
                key: 'KkLElRajHS5P1ehd',
                iv: 'EfrsVZqcNZmxLjWU'
            }
        }
    },

    // 验证手机号
    phone(phones) {
        var myreg = /^[1][0-9][0-9]{9}$/;
        return !myreg.test(phones) ? false : true;
    },

    // 返回顶部
    backTop(value = 10) {
        setTimeout(() => {
            let box = document.querySelector('#globalApp')
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                box.scrollTop;
            if (scrollTop > 0) {
                scrollTop -= value;
                box.scrollTop = document.documentElement.scrollTop = scrollTop;
                this.backTop(value * value);
            }
        }, 10);
    },

    // 数字千分化
    numThousand(num) {
        let value = Number(num);
        if (!value) return 0
        // 获取整数部分
        const intPart = Math.trunc(value)
        // 整数部分处理，增加,
        const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        // 预定义小数部分
        let floatPart = ''
        // 将数值截取为小数部分和整数部分
        const valueArray = value.toString().split('.')
        if (valueArray.length === 2) { // 有小数部分
            floatPart = valueArray[1].toString() // 取得小数部分
            return intPartFormat + '.' + floatPart
        }
        return intPartFormat + floatPart
    },

    // 关闭加载
    closeLoading() {
        if (this.elLoading) this.elLoading.close();
        if (this.elRefLoading) this.elRefLoading.close();
    },

    // 判断渲染字段
    verificationFields(data, key, init) {
        let name = key ? data[key] : data;
        if (!name) name = init ? init : '';

        return JSON.parse(JSON.stringify(name));
    },

    // 下载文件
    downloadFile(fileUrl, fileName) {
        if (!fileUrl) return false;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        window.open(fileUrl, '_blank');
        return true;
    },

    // 节流
    throttle(fn, delay = 300) {
        if (this.throttleTimer == null) {
            this.throttleTimer = setTimeout(() => {
                fn()

                clearTimeout(this.throttleTimer)
                this.throttleTimer = null
            }, delay);
        }
    },

    // 防抖
    debounce(fn, delay = 300) {
        return function (...args) {
            clearTimeout(this.debounceTimer)
            this.debounceTimer = setTimeout(() => {
                fn.call(this, ...args)
            }, delay);
        }
    },

    // 富文本图片优化
    rickTextInit(content) {
        const regex = new RegExp('<img', 'gi');
        return !content ? content : content.replace(regex, `<img style="max-width: 100%;"`)
    },

    /**
     * @dataStorageHanle 缓存数据操作
     * @param {String} type  类型 get:获取 set:存储 query:查询
     * @param {String} key   字段
     */
    dataStorageHanle(type = 'get', key, value) {
        if (!key) return new Error('请传入查询标识');

        const list = storage.get(key);

        // 获取
        if (type == 'get') return typeof list === 'string' && list ? JSON.parse(list) : {};
        // 查询
        if (type == 'query') return typeof list === 'string' && list ? true : false;
    },

    // 获取用户数据
    getUserInfo() {
        return new Promise((resolve, reject) => {
            request.get('/member/information').then((res) => {
                if (res.data.code == 200) {
                    // 存储用户信息
                    localStorage.setItem('userInfo', JSON.stringify(res.data.data));
                    store.dispatch('setUserInfo', res.data.data);

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },

    /**
     * @browserIsHide 判断浏览器的隐身(无痕)模式
     */
    browserIsHide() {
        let fs = window.RequestFileSystem || window.webkitRequestFileSystem;
        if (!fs) {
            console.log("check failed?");
        } else {
            fs(window.TEMPORARY, 100, function () {
                console.log('非无痕模式')
            }, function () {
                alert('您已开启无痕模式，为了不影响正常功能使用，请立即关闭！');
            });
        }
    },

    /**
     * @showIntroduce 是否展示老师介绍
     */
    showIntroduce(id, is_type = 'get') {
        // 获取本地缓存'老师介绍id'
        let introduceIds = localStorage.getItem('introduceIds');
        introduceIds = typeof introduceIds && introduceIds ? introduceIds.split(',') : []; // 字符串转数组

        // 判断'老师介绍id'是否存在，否则存储
        let exist_id = introduceIds.findIndex(el => el == id) == -1;
        exist_id && introduceIds.push(id);
        is_type == 'set' && localStorage.setItem('introduceIds', introduceIds.join(','));

        return exist_id
    },

    /**
     * @获取网站信息
     */

    // 获取网站信息
    getBase() {
        request.get('/configs/base').then((res) => {
            if (res.data.data) {
                store.dispatch('setOfficialInfo', res.data.data);
            }
        })
    },


    /**
     * @获取秘钥
     */
    getVideoKey() {
        let { key, iv } = this.data().videoCryptoData;
        let keyLeft = this.getKeyLeft();
        let keyRight = this.getKeyRight();
        let keyResult = [...this.getDecrypt(key, iv, keyLeft).split(','), ...this.getDecrypt(key, iv, keyRight).split(',')]
        let nowKey1 = keyResult.filter((item, index) => index % 2 === 0).join('');
        let nowKey2 = keyResult.filter((item, index) => index % 2 !== 0).join('');
        
        let ivLeft = this.getIvLeft();
        let ivRight = this.getIvRight();
        let ivResult = [...this.getDecrypt(key, iv, ivLeft).split(','), ...this.getDecrypt(key, iv, ivRight).split(',')]
        let nowIv1 = ivResult.filter((item, index) => index % 2 === 0).join('');
        let nowIv2 = ivResult.filter((item, index) => index % 2 !== 0).join('');

        return {
            SECRET_KEY: nowKey1 + nowKey2,
            SECRET_IV: nowIv1 + nowIv2
        }
    },

    getKeyLeft() {
        let datas = ['a', 'x', 'P', '8', 'h', 'D', 'E', 'I'];

        return this.getEncipher(datas);
    },
    getKeyRight() {
        let datas = ['L', 'f', 'S', 'A', 's', 'c', '4', 'B'];

        return this.getEncipher(datas);
    },

    getIvLeft() {
        let datas = ['2', 'f', '8', '1', '2', 'z', '7', 'e'];

        return this.getEncipher(datas);
    },
    getIvRight() {
        let datas = ['h', 'o', 'd', 'g', '6', 'h', 'n', 'f'];

        return this.getEncipher(datas);
    },

    // 加密
    getEncipher(value, type = '', datas) {
        let key, iv;
        if (type == 'server') {
            key = datas.key;
            iv = datas.iv;
        } else {
            key = this.data().videoCryptoData.key;
            iv = this.data().videoCryptoData.iv;
        }

        let SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
        let SECRET_IV = CryptoJS.enc.Utf8.parse(iv);
        
        let srcs = CryptoJS.enc.Utf8.parse(value);
        let encrypted = CryptoJS.AES.encrypt(srcs, SECRET_KEY, {
            iv: SECRET_IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
        return encrypted.toString();
    },

    // 解密
    getDecrypt(key, iv, value) {
        let datas = value.replace(/\n*$/g, '').replace(/\n/g, ''); //增加这一行，将换行符替换为空
        let SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
        let SECRET_IV = CryptoJS.enc.Utf8.parse(iv);

        const decryptedStr = CryptoJS.AES.decrypt(datas, SECRET_KEY, {
            iv: SECRET_IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);

        return decryptedStr
    },
}
