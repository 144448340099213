import { createStore } from 'vuex'

export default createStore({
  state: {
    officialInfo: {}, // 网站信息
    userInfo: {}, // 用户信息
  },
  getters: {
  },
  mutations: {
    // 获取个人信息
    setUserInfo(state, playod) {
      state.userInfo = playod;
    },

    // 获取网站信息
    setOfficialInfo(state, playod) {
      state.officialInfo = playod;
    }
  },
  actions: {
    // 获取个人信息
    setUserInfo(content, playod) {
      content.commit('setUserInfo', playod);
    },

    // 获取网站信息
    setOfficialInfo(content, playod) {
      content.commit('setOfficialInfo', playod);
    },
  },
  modules: {
  }
})
